import dynamic from "next/dynamic";
import SbEditable from "storyblok-react";

import type { BlogBar as BlogBarType } from "./blocks/BlogBar";
import type { CallToAction as CallToActionType } from "./blocks/CallToAction";
import type { CardsBar as CardsBarType } from "./blocks/CardsBar";
import type { Categories as CategoriesType } from "./blocks/Categories";
import type { DynamicSection as DynamicSectionType } from "./blocks/Dynamic";
import type { DynamicHeader as DynamicHeaderType } from "./blocks/DynamicHeader";
import type { Hero as HeroType } from "./blocks/Hero";
import type { Highlight as HighlightType } from "./blocks/Highlight";
import type { ImageBanner as ImageBannerType } from "./blocks/ImageBanner";
import type { IngredientsCards as IngredientsCardsType } from "./blocks/IngredientsCards";
import type { PopularProducts as PopularProductsType } from "./blocks/PopularProducts";
import type { ProductCardBig as ProductCardBigType } from "./blocks/ProductCardBig";
import type { ProductChoice as ProductChoiceType } from "./blocks/ProductChoice";

import type { UspBar as UspBarType } from "./blocks/UspBar";
import { Container } from "./molecules/Container";

const BlogBar = dynamic(() =>
  import("./blocks/BlogBar").then((mod) => mod.BlogBar)
) as typeof BlogBarType;
const CardsBar = dynamic(() =>
  import("./blocks/CardsBar").then((mod) => mod.CardsBar)
) as typeof CardsBarType;
const CallToAction = dynamic(() =>
  import("./blocks/CallToAction").then((mod) => mod.CallToAction)
) as typeof CallToActionType;
const Categories = dynamic(() =>
  import("./blocks/Categories").then((mod) => mod.Categories)
) as typeof CategoriesType;
const DynamicSection = dynamic(() =>
  import("./blocks/Dynamic").then((mod) => mod.DynamicSection)
) as typeof DynamicSectionType;
const Hero = dynamic(() =>
  import("./blocks/Hero").then((mod) => mod.Hero)
) as typeof HeroType;
const Highlight = dynamic(() =>
  import("./blocks/Highlight").then((mod) => mod.Highlight)
) as typeof HighlightType;
const ImageBanner = dynamic(() =>
  import("./blocks/ImageBanner").then((mod) => mod.ImageBanner)
) as typeof ImageBannerType;
const PopularProducts = dynamic(() =>
  import("./blocks/PopularProducts").then((mod) => mod.PopularProducts)
) as typeof PopularProductsType;
const ProductChoice = dynamic(() =>
  import("./blocks/ProductChoice").then((mod) => mod.ProductChoice)
) as typeof ProductChoiceType;
const UspBar = dynamic(() =>
  import("./blocks/UspBar").then((mod) => mod.UspBar)
) as typeof UspBarType;
const ProductCardBig = dynamic(() =>
  import("./blocks/ProductCardBig").then((mod) => mod.ProductCardBig)
) as typeof ProductCardBigType;
const DynamicHeader = dynamic(() =>
  import("./blocks/DynamicHeader").then((mod) => mod.DynamicHeader)
) as typeof DynamicHeaderType;
const IngredientsCards = dynamic(() =>
  import("./blocks/IngredientsCards").then((mod) => mod.IngredientsCards)
) as typeof IngredientsCardsType;

const Sections = {
  blog_bar: BlogBar,
  cards_bar: CardsBar,
  call_to_action: CallToAction,
  categories: Categories,
  dynamic_section: DynamicSection,
  hero: Hero,
  highlight: Highlight,
  image_banner: ImageBanner,
  popular_products: PopularProducts,
  product_bar: PopularProducts,
  product_choice: ProductChoice,
  usp_bar: UspBar,
  product_card: ProductCardBig,
  dynamic_header: DynamicHeader,
  ingredient_cards: IngredientsCards,
};

export const DynamicSections = ({ sections }) => {
  return sections?.length
    ? sections?.map((section, i) => {
        if (!!section && typeof Sections[section?.component] !== "undefined") {
          const Section = Sections[section?.component];

          if (
            section?.component === "hero" ||
            section?.component === "call_to_action" ||
            section?.component === "image_banner"
          ) {
            return (
              <Section key={section?._uid} content={section} first={i === 0} />
            );
          }
          return (
            <SbEditable content={section} key={section?._uid}>
              <Container
                space={[3, 4]}
                maxWidth={"1332px"}
                px={2}
                textAlign={section?.text_align}
                alignItems="start"
                withOverflowHidden={
                  section?.component === "dynamic_section" ||
                  section?.component === "highlight" ||
                  section?.component === "call_to_action" ||
                  section?.component === "usp_bar" ||
                  section?.component === "categories" ||
                  section?.component === "popular_products" ||
                  section?.component === "product_bar" ||
                  section?.component === "recipe" ||
                  section?.component === "recipe_bar"
                }
              >
                <Section first={i === 0} content={section} />
              </Container>
            </SbEditable>
          );
        }

        return null;
      })
    : null;
};
